import { useNavigate } from "@solidjs/router";
import { logoutWhiteIcon } from "~/assets/assets";
import { CloseModalButton, useModal } from "~/components/modal";
import { clientRepo } from "~/server/apis/client_repo";
import { Cookie } from "~/types";
import { clearCookie } from "~/utils/client_cookie";

export const LogoutModal = () => {
  const navigate = useNavigate();
  const { closeModal } = useModal()!;

  const logoutHandler = async () => {
    await clientRepo.logout();
    clearCookie(Cookie.SessionId);
    navigate("/pluto/login", { replace: true });
    closeModal();
  };

  return (
    <div class="relative p-2 pt-4 lg:p-4">
      <div class="absolute right-2 top-2 lg:hidden" onClick={closeModal}>
        <CloseModalButton />
      </div>
      <div class="flex flex-col items-center justify-center gap-3">
        <div class="flex h-[66px] w-[66px] items-center justify-center rounded-full bg-basePrimaryDark py-[17px] pl-[17.5px] pr-[16.5px]">
          <img src={logoutWhiteIcon} alt="logout" />
        </div>
        <p class="font-jakartaSans text-h3 text-white">Are you sure?</p>
        <p class="text-center text-medium text-baseSecondaryLight">
          You'll need to log in again to access your chats and the gifts created
          with Pluto.
        </p>
      </div>
      <div class="mt-8 flex justify-center gap-3">
        <button
          class="h-11 w-[165.5px] rounded-[37px] border border-baseSecondaryDark bg-transparent text-mediumBold text-white"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          class="h-11 w-[165.5px] rounded-[37px] bg-errorDark text-mediumBolder text-white"
          onClick={logoutHandler}
        >
          Yes, log out
        </button>
      </div>
    </div>
  );
};
